import { css } from 'styled-components'
import { fluidRange } from 'polished'

export interface StyleParams {
	m?: string
	mt?: string
	mr?: string
	mb?: string
	ml?: string
	pt?: string
	pr?: string
	pb?: string
	pl?: string
	color?: 'secondary' | 'green' | 'gray' | 'white'
	textAlign?: string
}

export const styleParams = css<StyleParams>`
	margin: ${({ m }) => m || null};
	margin-top: ${({ mt }) => mt || null};
	margin-right: ${({ mr }) => mr || null};
	margin-bottom: ${({ mb }) => mb || null};
	margin-left: ${({ ml }) => ml || null};
	padding-top: ${({ pt }) => pt || null};
	padding-right: ${({ pr }) => pr || null};
	padding-bottom: ${({ pb }) => pb || null};
	padding-left: ${({ pl }) => pl || null};
	color: ${({ color, theme }) => (color ? theme.colors[color] : '')};
	text-align: ${({ textAlign }) => textAlign || null};
`

export const withFancyScrollbar = css`
	&::-webkit-scrollbar {
		display: block;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.05);
	}

	&::-webkit-scrollbar-thumb {
		background: #bbb;
		border-radius: 100px;
	}
`

export const withFancyHorizontalScrollbar = css`
	&::-webkit-scrollbar {
		display: block;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.05);
	}

	&::-webkit-scrollbar-thumb {
		background: #bbb;
		border-radius: 100px;
	}
`

export const fluid = (prop: string, fromSize: string, toSize: string, screenMin = '320px', screenMax = '1920px') =>
	fluidRange(
		{
			prop: prop,
			fromSize: fromSize,
			toSize: toSize,
		},
		screenMin,
		screenMax
	)

export const sequencedAnimationDelay = (count: number, intensity: number) => {
	const arr = [...Array(count)].map((_key, index) => index)
	const value = arr.reduce(
		(prev, curr) => prev + `&:nth-child(${curr}) { animation-delay: ${(curr - 1) * intensity}s; }`,
		''
	)

	return css`
		${value}
	`
}
