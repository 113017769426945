import { css, ThemedCssFunction, DefaultTheme } from 'styled-components'

export const containerBreakpoint = 1736
export const containerMaxWidth = 1576
export const containerLargeMaxWidth = 1920
export const containerContentMaxWidth = 1310

interface Media {
	mobileOnly: ThemedCssFunction<DefaultTheme>
	mobileSmall: ThemedCssFunction<DefaultTheme>
	mobileSmallOnly: ThemedCssFunction<DefaultTheme>
	mobileMedium: ThemedCssFunction<DefaultTheme>
	mobileMediumOnly: ThemedCssFunction<DefaultTheme>
	mobileLarge: ThemedCssFunction<DefaultTheme>
	mobileLargeOnly: ThemedCssFunction<DefaultTheme>
	tablet: ThemedCssFunction<DefaultTheme>
	tabletOnly: ThemedCssFunction<DefaultTheme>
	tabletAndMobileOnly: ThemedCssFunction<DefaultTheme>
	desktopSmall: ThemedCssFunction<DefaultTheme>
	desktopSmallOnly: ThemedCssFunction<DefaultTheme>
	desktopLarge: ThemedCssFunction<DefaultTheme>
	desktopLargeOnly: ThemedCssFunction<DefaultTheme>
	desktopHuge: ThemedCssFunction<DefaultTheme>
	containerContentMaxWidth: ThemedCssFunction<DefaultTheme>
	containerBreakpoint: ThemedCssFunction<DefaultTheme>
	containerMaxWidth: ThemedCssFunction<DefaultTheme>
	containerLargeMaxWidth: ThemedCssFunction<DefaultTheme>
	retina: ThemedCssFunction<DefaultTheme>
}

const sizes = {
	mobileOnly: [0, 767],
	mobileSmall: 320,
	mobileSmallOnly: [320, 375],
	mobileMedium: 375,
	mobileMediumOnly: [375, 424],
	mobileLarge: 425,
	mobileLargeOnly: [425, 767],
	tablet: 768,
	tabletOnly: [768, 1023],
	tabletAndMobileOnly: [0, 1023],
	desktopSmall: 1024,
	desktopSmallOnly: [1024, 1439],
	desktopLarge: 1440,
	desktopLargeOnly: [1440, 1920],
	desktopHuge: 1920,
	containerContentMaxWidth: containerContentMaxWidth,
	containerBreakpoint: containerBreakpoint,
	containerMaxWidth: containerMaxWidth,
	containerLargeMaxWidth: containerLargeMaxWidth,
	retina: 2560,
}

// Iterate through the sizes and create a media template
// eslint-disable-next-line
export const media: Media = Object.keys(sizes).reduce((accumulator: any, label: string) => {
	// @ts-ignore
	if (Array.isArray(sizes[label])) {
		// @ts-ignore
		accumulator[label] = (...args) => css`
			// @ts-ignore
			@media (min-width: ${sizes[label][0]}px) and (max-width: ${sizes[label][1]}px) {
				// @ts-ignore
				${css(...args)}
			}
		`
	} else {
		// @ts-ignore
		accumulator[label] = (...args) => css`
			// @ts-ignore
			@media (min-width: ${sizes[label]}px) {
				// @ts-ignore
				${css(...args)}
			}
		`
	}
	return accumulator
}, {})
